import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {UserComponent} from './user.component';

const userRoutes: Routes = [
  {
    path: ':state', component: UserComponent
  },
  {
    path: '',
    redirectTo: '/app/users/all',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(userRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class UserRoutingModule {
}
