import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IPostApprovalSearchRequest, IPostNewsSummary, IPostSummary} from '../@types/post';

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
    console.log('created');
  }

  search(req: IPostApprovalSearchRequest): Observable<IPage<IPostSummary>> {
    return this.http.post<IPage<IPostSummary>>(`${this.apiURL}/admin/post/search`, req);
  }

  changeCategory(postId: number, categoryId: number): Observable<void> {
    return <any> this.http.put<any>(`${this.apiURL}/admin/post/${postId}/changeCategory/${categoryId}`, {});
  }

  publish(postId: number): Observable<any> {
    return this.http.put<IPage<IPostSummary>>(`${this.apiURL}/admin/post/${postId}/publish`, {});
  }

  archive(postId: number): Observable<any> {
    return this.http.put<IPage<IPostSummary>>(`${this.apiURL}/admin/post/${postId}/archive`, {});
  }

  draft(postId: number): Observable<any> {
    return this.http.put<IPage<IPostSummary>>(`${this.apiURL}/admin/post/${postId}/draft`, {});
  }

  approval(postId: number): Observable<any> {
    return this.http.put<IPage<IPostSummary>>(`${this.apiURL}/admin/post/${postId}/approval`, {});
  }

  duplicate(postId: number): Observable<any> {
    return this.http.put<IPage<IPostSummary>>(`${this.apiURL}/admin/post/${postId}/duplicate`, {});
  }

  loadPostNewsSummary(postId: number): Observable<IPostNewsSummary> {
    return this.http.get<IPostNewsSummary>(`${this.apiURL}/admin/post/${postId}/postNewsSummary`);
  }

  updatePostNews(postId: number, post: IPostNewsSummary): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/admin/post/${postId}/updatePostNews`, post);
  }

}
