<div class="container">

  <!-- Filter Turgay -->
  <div class="row align-items-center mt-4 mb-2">
    <div class="col-lg-5 mb-2 mt-2">
      <h5>{{ posts ? posts.totalContent : 0}} Adet {{stateName}} Haber</h5>
    </div>

    <div class="col-lg-3 mb-2 mt-2">
      <div class="input-group md-form form-sm form-2 pl-0">
        <input class="form-control my-0 py-1 red-border" type="text" [(ngModel)]="searchReq.freeText"
               placeholder="içerik adı, haber id..." aria-label="Search">
        <div class="input-group-append" (click)="search()">
          <span class="input-group-text red lighten-3" id="basic-text1"><i class="fas fa-search text-grey"
                                                                           aria-hidden="true"></i></span>
        </div>
      </div>
    </div>

    <div class="col-lg-4 mb-2 mt-2 d-flex flex-row-reverse bd-highlight">

      <div class="btn-group btn-group-toggle" data-toggle="buttons">

        <label [routerLinkActive]="['active']" routerLink="/app/enthusiasm/waiting" class="btn btn-info">
          <input type="radio" name="options" id="option2" autocomplete="off"> Onay
        </label>

        <label [routerLinkActive]="['active']" routerLink="/app/enthusiasm/published" class="btn btn-info">
          <input type="radio" name="options" id="option3" autocomplete="off"> Yayinda
        </label>


        <label [routerLinkActive]="['active']" routerLink="/app/enthusiasm/archive" class="btn btn-info">
          <input type="radio" name="options" id="option1" autocomplete="off" checked> Arsiv
        </label>


      </div>
    </div>
  </div>

  <div *ngIf="posts">
    <div *ngFor="let post of posts.content " class="row newscontainer align-items-center">
      <div class="col-lg-2 mb-2 mt-2">
        <img [src]="post.imageUrl" alt="..." class="img-thumbnail">
      </div>
      <div class="col-lg-5 mb-2 mt-2 pl-0"><a>
        <h5 style="word-break: break-word" [innerHTML]="post.title"></h5>
      </a>
        <p style="word-break: break-word" class="post-summary" [innerHTML]="post.summary">{{post.summary}} </p>
      </div>

      <div class="col-lg-1 mb-2 mt-2 text-center pr-0 pl-0">
        <p>{{post.sourceName}}</p>
        <p>{{post.categoryName}}</p>
      </div>

      <div class="col-lg-2 mb-2 mt-2 text-center">
        <a type="button" class="btn btn-outline-secondary btn-sm btn-block">{{post.strDate}}</a>
        <a class="btn btn-outline-secondary btn-sm btn-block">ID: {{post.postId}}</a>
      </div>

      <div class="col-lg-2 mb-2 mt-2 text-center">
        <button type="button" *ngIf="selectedStatus !=='DRAFT'" class="btn btn-danger btn-sm btn-block"
                (click)="draft(post)">Taslaga Geri Gonder
        </button>
        <button type="button" *ngIf="selectedStatus !=='PUBLISHED'" class="btn btn-primary btn-sm btn-block"
                (click)="publish(post)">Yayınla
        </button>
        <button type="button" *ngIf="selectedStatus !=='PUBLISHED'" class="btn btn-secondary btn-sm btn-block"
                (click)="archive(post)">Arşiv
        </button>

        <a type="button" href="{{pozyMainUrl}}/haber-onizle/{{post.postId}}" target="_blank"
           class="btn btn-outline-secondary btn-sm btn-block">Onizle
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="posts && posts.totalContent > searchReq.size"
       class="col-md-12 d-flex justify-content-center align-items-center mb-3 mt-3">

    <ngb-pagination (pageChange)="pageEvent($event)" [collectionSize]="posts.totalContent" [(page)]="searchReq.page"
                    [maxSize]="10"></ngb-pagination>

  </div>

</div>
