<div class="container">

  <!-- Filter Turgay -->
  <div class="row align-items-center mt-4 mb-2">
    <div class="col-lg-5 mb-2 mt-2">
      <h5>{{ posts ? posts.totalContent : 0}} Adet {{stateName}} Haber</h5>
    </div>

    <div class="col-lg-3 mb-2 mt-2">
      <div class="input-group md-form form-sm form-2 pl-0">
        <input class="form-control my-0 py-1 red-border" type="text" [(ngModel)]="searchReq.freeText"
               placeholder="içerik adı, haber id..." aria-label="Search">
        <div class="input-group-append" (click)="search()">
          <span class="input-group-text red lighten-3" id="basic-text1"><i class="fas fa-search text-grey"
                                                                           aria-hidden="true"></i></span>
        </div>
      </div>
    </div>


    <!--    <div class="col-lg-4 mb-2 mt-2 d-flex flex-row-reverse bd-highlight">-->
    <!--      <button class="btn  btn-sm m-1" *ngFor="let menu of statusMenus"-->
    <!--              [ngClass]="{'btn-dark':menu.active , 'btn-outline-dark':!menu.active}"-->
    <!--              (click)="selectStatus(menu)">{{menu.name}}</button>-->
    <!--      &lt;!&ndash;      <a href="haberonay-arsiv.html">`&ndash;&gt;-->
    <!--      &lt;!&ndash;        <button type="button" class="btn btn-outline-dark btn-sm m-1">Arşiv</button></a><a href="haberonay-taslak.html"><button type="button" class="btn btn-dark btn-sm m-1">Taslak</button></a><a href="haberonay-yayinda.html"><button type="button" class="btn btn-outline-dark btn-sm m-1">Yayında</button></a>&ndash;&gt;-->
    <!--    </div>-->

    <div class="col-lg-4 mb-2 mt-2 d-flex flex-row-reverse bd-highlight">

      <div class="btn-group btn-group-toggle" data-toggle="buttons">

        <label [routerLinkActive]="['active']" routerLink="/app/approve/waiting" class="btn btn-info">
          <input type="radio" name="options" id="option2" autocomplete="off"> Onay
        </label>

        <label [routerLinkActive]="['active']" routerLink="/app/approve/published" class="btn btn-info">
          <input type="radio" name="options" id="option3" autocomplete="off"> Yayinda
        </label>


        <label [routerLinkActive]="['active']" routerLink="/app/approve/archive" class="btn btn-info">
          <input type="radio" name="options" id="option1" autocomplete="off" checked> Arsiv
        </label>


        <label [routerLinkActive]="['active']" routerLink="/app/approve/duplicate" class="btn btn-info">
          <input type="radio" name="options" id="option4" autocomplete="off" checked> Mukerrer
        </label>

      </div>
    </div>

  </div>

  <div class="row" *ngIf="selectedStatus ==='WAITING_APPROVAL'">
    <div class="col-md-12 mb-2">
      <button type="button" [disabled]="disableArchiveAll" class="btn btn-danger btn-block" (click)="archiveAll()">
        Hepsini Arşivle
      </button>
    </div>
  </div>

  <!-- BOX Turgay -->
  <div>
    <div *ngFor="let post of posts.content ;trackBy: trackByMethod " class="row newscontainer align-items-center">
      <div class="col-lg-2 mb-2 mt-2">
        <img [src]="post.imageUrl" alt="..." class="img-thumbnail">
      </div>
      <div class="col-lg-5 mb-2 mt-2 pl-0 " [ngClass]="{'text-center':mobile}">
        <a target="_blank" [routerLink]="'/app/post/'+post.postId"><i class="fas fa-pencil-alt edit-news"></i></a>
        <a>
        <h5 [innerHTML]="post.title"></h5>
        </a>
        <p class="post-summary " [ngClass]="{'text-center':mobile}" [innerHTML]="post.summary"></p>
      </div>

      <div *ngIf="!mobile" class="col-lg-1 mb-2 mt-2  text-center pr-0 pl-0">
        <p>{{post.sourceName}}</p>
        <p>{{post.categoryName}}</p>
      </div>

      <div *ngIf="mobile" class="col-lg-1 mb-2 mt-2  text-center pr-0 pl-0">
        <p><strong>{{post.sourceName}} - {{post.categoryName}} / {{post.strDate}}</strong></p>
      </div>

      <div class="col-lg-2 mb-2 mt-2 text-center d-none d-sm-block pl-1 pr-1">
        <select class="form-control mb-2" [(ngModel)]="post.objectiveId" (change)="onObjectiveChange(post)">
          <option [ngValue]="-1">Kuresel Amac Sec</option>
          <option *ngFor="let go of globalObjectives" [ngValue]="go.id">
            {{ go.name }}
          </option>

        </select>

        <select class="form-control mb-2" [(ngModel)]="post.categoryId" (change)="onCategoryChange(post)">

          <option *ngFor="let go of categories" [ngValue]="go.id">
            {{ go.name }}
          </option>

        </select>

        <a type="button" class="btn btn-outline-secondary btn-sm btn-block">{{post.strDate}}</a>
        <a class="btn btn-outline-secondary btn-sm btn-block">ID: {{post.postId}}</a>
      </div>

      <div class="col-lg-2 mb-2 mt-2 text-center" *ngIf="!mobile">

        <button type="button" *ngIf="selectedStatus !=='FILTERED'" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}"
                class="btn btn-danger btn-block" (click)="archive(post)">Arşiv
        </button>
        <button type="button" *ngIf="selectedStatus !=='PUBLISHED'" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}"
                class="btn btn-primary  btn-block" (click)="publish(post)">Yayınla
        </button>
        <button type="button" *ngIf="selectedStatus!=='DUPLICATE'" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}"
                class="btn btn-info  btn-block" (click)="duplicate(post)">Mukerrer
        </button>
        <button type="button" *ngIf="selectedStatus !=='WAITING_APPROVAL'" class="btn btn-secondary  btn-block"
                [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}" (click)="approval(post)">Onaya Gonder
        </button>

        <a type="button" href="{{pozyMainUrl}}/haber-onizle/{{post.postId}}" target="_blank"
           class="btn btn-outline-secondary  btn-block" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}">
           {{selectedStatus ==='PUBLISHED' ? 'Habere Git' : 'Onizle'}}
        </a>

      </div>


      <div class="row col-md-12 pr-0" *ngIf="mobile">

        <select class="form-control ml-2 mr-2 mb-2" [(ngModel)]="post.objectiveId" (change)="onObjectiveChange(post)">
          <option [ngValue]="-1">Kuresel Amac Sec</option>
          <option *ngFor="let go of globalObjectives" [ngValue]="go.id">
            {{ go.name }}
          </option>

        </select>

        <select class="form-control ml-2 mr-2 mb-2" [(ngModel)]="post.categoryId" (change)="onCategoryChange(post)">

          <option *ngFor="let go of categories" [ngValue]="go.id">
            {{ go.name }}
          </option>

        </select>

        <div class="col" *ngIf="selectedStatus !=='FILTERED'">
          <button type="button" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}"
                  class="btn btn-danger btn-block" (click)="archive(post)">Arşiv
          </button>

        </div>
        <div class="col" *ngIf="selectedStatus !=='PUBLISHED'">
          <button type="button" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}"
                  class="btn btn-primary  btn-block" (click)="publish(post)">Yayınla
          </button>
        </div>
        <div class="col" *ngIf="selectedStatus!=='DUPLICATE'">
          <button type="button" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}"
                  class="btn btn-info  btn-block" (click)="duplicate(post)">Mukerrer
          </button>
        </div>

        <div class="col" *ngIf="selectedStatus !=='WAITING_APPROVAL'">

          <button type="button" class="btn btn-secondary  btn-block"
                  [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}" (click)="approval(post)">Onaya Gonder
          </button>
        </div>

        <div class="col">
          <a type="button" href="{{pozyMainUrl}}/haber-onizle/{{post.postId}}" target="_blank"
             class="btn btn-outline-secondary  btn-block" [ngClass]="{'btn-sm':!mobile,'mb-2':mobile}">Onizle</a>
        </div>

      </div>
    </div>

    <div *ngIf="posts && posts.totalContent > searchReq.size"
         class="col-md-12 d-flex justify-content-center align-items-center mb-3 mt-3">

      <ngb-pagination (pageChange)="pageEvent($event)" [collectionSize]="posts.totalContent" [(page)]="searchReq.page"
                      [maxSize]="10"></ngb-pagination>

    </div>
  </div>

</div>
