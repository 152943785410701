import {Component, OnInit} from '@angular/core';
import {UserService} from '../../../_services/user.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;

  constructor(private us: UserService, private route: Router) {
  }

  ngOnInit(): void {
  }

  public login() {
    this.us.login(this.username, this.password).subscribe((resp) => {
      location.reload();
    });
  }

}
