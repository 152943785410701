import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApproveComponent} from './approve.component';
import {ApproveRoutingModule} from './approve-routing.module';
import {FormsModule} from '@angular/forms';
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ApproveComponent],
  imports: [
    CommonModule,
    ApproveRoutingModule,
    FormsModule,
    NgbPaginationModule
  ]
})
export class ApproveModule {
}
