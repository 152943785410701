<header>


  <nav *ngIf="simpleUser" class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark fixed-top profile-navigation">
    <div class="container">
      <a class="navbar-brand" href="ozet.html"><img src="https://pozy.org/assets/images/pozy-logo.svg"></a>

      <span class="badge badge-success"><i class="fas fa-heart" aria-hidden="true"></i> Yeni Gönüllü (2.Gün)</span>
      <ul class="navbar-nav ml-auto">
        <li class="mr-2 dropdown" ngbDropdown>

          <a class=" dropdown-toggle" id="navbarDropdownBlog" ngbDropdownToggle
             aria-haspopup="true" aria-expanded="false">
            <img src="{{simpleUser.avatarUrl}}" alt="..." class="img-thumbnail" width="40" height="40"> {{simpleUser.name}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
            <a class="dropdown-item" href="blog-home-1.html">Profil</a>
            <a class="dropdown-item" href="blog-home-2.html">Ayarlar</a>
            <a class="dropdown-item" href="blog-post.html"><i class="fas fa-sign-out-alt" aria-hidden="true"></i>
              Çıkış Yap</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>


  <nav class="navbar navbar-expand-md  bg-white pozy-navigation ">


    <button type="button" class="navbar-toggler float-right" (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed" data-toggle="collapse" data-target="#navbarCollapse">
      <a class=""><i class="fas fa-bars"></i></a>
    </button>

    <div class="collapse navbar-collapse justify-content-between" [ngbCollapse]="isCollapsed">
      <div class="container">
        <ul class="navbar-nav">
          <li class="nav-item" [routerLinkActive]="['active']" routerLink="/app/home">
            <a class="nav-link"><i
              class="fas fa-chart-pie"
              aria-hidden="true"></i> Özet</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']" routerLink="/app/approve">
            <a class="nav-link"><i class="fas fa-newspaper"
                                   aria-hidden="true"></i>
              Haber
              Onay</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['active']" routerLink="/app/enthusiasm">
            <a class="nav-link"><i class="fas fa-grin-hearts" aria-hidden="true"></i> Coşku </a>
          </li>

          <li class="nav-item" [routerLinkActive]="['active']" routerLink="/app/users">
            <a class="nav-link"><i class="fas fa-grin-hearts" aria-hidden="true"></i> Üyeler </a>
          </li>
        </ul>
      </div>
    </div>


  </nav>

</header>
