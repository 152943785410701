import {Component} from '@angular/core';
import {PostService} from '../../_services/post.service';
import {IPostSummary} from '../../@types/post';
import {ActivatedRoute} from '@angular/router';
import {AbstractCommonSearch} from '../common/AbstractCommonSearch';
import {forkJoin, Observable} from 'rxjs';
import {GlobalObjectiveService} from '../../_services/global.objective.service';
import {CategoryService} from '../../_services/category.service';

@Component({
  selector: 'app-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent extends AbstractCommonSearch {

  public disableArchiveAll: boolean;

  constructor(ps: PostService, route: ActivatedRoute, go: GlobalObjectiveService, cs: CategoryService) {
    super(ps, route, go, cs);
    this.searchReq = {
      type: 'NEWS_SOURCE',
      size: 50
    };
    this.routeStateStatus = [
      {
        state: 'waiting',
        status: 'WAITING_APPROVAL',
        name: 'Onay Bekliyor'
      },
      {
        state: 'published',
        status: 'PUBLISHED',
        name: 'Yayinda'
      },
      {
        state: 'archive',
        status: 'FILTERED',
        name: 'Arsiv'
      },
      {
        state: 'duplicate',
        status: 'DUPLICATE',
        name: 'Mukerrer'
      }];
  }

  archive(x: IPostSummary) {
    this.ps.archive(x.postId).subscribe(() => {
      this.search();
    });
  }

  archiveAll() {
    this.disableArchiveAll = true;
    const arr: Observable<any>[] = [];
    this.posts.content.forEach((x) => {
      arr.push(this.ps.archive(x.postId));
    });

    forkJoin(arr).subscribe(() => {
      this.search();
      this.disableArchiveAll = false;
    });
  }

  duplicate(x: IPostSummary) {
    this.ps.duplicate(x.postId).subscribe(() => {
      this.search();
    });
  }

}
