import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
export class SecurityInterceptor implements HttpInterceptor {

  private token = '';

  constructor(private router: Router) {
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      this.router.navigate(['/auth']);
    }
    return throwError(err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let cloneReq;
    if (this.token) {
      cloneReq = req.clone({
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.token
        })
      });
    } else {
      cloneReq = req;
    }
    return next.handle(cloneReq).pipe(catchError(x => this.handleAuthError(x)));
  }
}
