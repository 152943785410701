import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../_services/user.service';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  private currentState: string;

  private routeStateStatus = [
    {
      state: 'all',
      status: ''
    },
    {
      state: 'active',
      status: 'ACTIVE'
    },
    {
      state: 'passive',
      status: 'PASSIVE'
    }];

  public users: IPage<IUser>;
  public searchReq: IUserSearchRequest = {
    size: 50
  };

  constructor(private route: ActivatedRoute, private us: UserService) {
    console.log(this);
  }


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // this.setupComponent(params['someParam']);
      if (this.currentState !== params.state) {
        this.initState(params.state);
      }
    });
  }

  search() {

    const req = cloneDeep(this.searchReq);
    req.page = req.page - 1;

    this.us.search(req).subscribe((resp) => {
      this.users = resp;
    });


  }

  pageEvent(page: number) {
    this.searchReq.page = page;
    this.search();
  }

  initState(x: string) {
    this.currentState = x;
    this.searchReq.status = this.findStateStatus(x);
    this.searchReq.page = 1;
    this.search();
  }

  findStateStatus(x: string): string {
    return this.routeStateStatus.filter((n) => n.state === x)[0].status;
  }


}
