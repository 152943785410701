import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../_services/user.service';


@Injectable()
export class GuestGuard implements CanActivate {


  constructor(private router: Router, private us: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((x) => {
      this.us.me().subscribe((resp) => {
        this.router.navigateByUrl('/app/approve');
        x.next(true);
        x.complete();
      }, error => {
        console.log('error');
        x.next(true);
        x.complete();
      });
    });
  }
}
