import {IGlobalObjectiveChangeReq, IPostApprovalSearchRequest, IPostSummary} from '../../@types/post';
import * as moment from 'moment';
import {PostService} from '../../_services/post.service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {OnInit} from '@angular/core';
import isMobile from 'ismobilejs';
import * as cloneDeep from 'lodash/cloneDeep';
import {GlobalObjectiveService} from '../../_services/global.objective.service';
import {CategoryService} from '../../_services/category.service';

export abstract class AbstractCommonSearch implements OnInit {

  public stateName: string;
  public selectedStatus: string;
  public mobile: boolean;
  private currentState: string;
  public posts: IPage<IPostSummary>;
  public searchReq: IPostApprovalSearchRequest;
  public pozyMainUrl = environment.pozyMainUrl;
  public routeStateStatus;
  public globalObjectives: IGlobalObjective[];
  public categories: ICategory[];

  protected constructor(protected ps: PostService, protected route: ActivatedRoute, protected go: GlobalObjectiveService,
                        protected cs: CategoryService) {
    this.mobile = isMobile(window.navigator).phone;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // this.setupComponent(params['someParam']);
      if (this.currentState !== params.state) {
        this.initState(params.state);
      }
    });
    this.go.loadAll().subscribe((resp) => {
      this.globalObjectives = resp;
    });

    this.cs.loadAll().subscribe((resp) => {
      this.categories = resp;
    });

  }

  onObjectiveChange(post: IPostSummary) {
    const req: IGlobalObjectiveChangeReq = {
      postId: post.postId,
      objectiveId: post.objectiveId
    };
    this.go.changeGlobalObjective(req).subscribe(() => {
      // do nothing
    });
  }

  onCategoryChange(post: IPostSummary) {
    this.ps.changeCategory(post.postId, post.categoryId)
      .subscribe(() => {

      });
  }

  search(deletedPost?: IPostSummary) {
    if (this.posts && deletedPost) {
      this.posts.content.splice(this.posts.content.indexOf(deletedPost), 1);
    }
    const req = cloneDeep(this.searchReq);
    req.page = req.page - 1;

    if (!this.posts || !deletedPost || (deletedPost && this.posts.content.length === 0)) {
      this.ps.search(req).subscribe((resp) => {
        this.posts = resp;
        console.log(this.posts);
        this.fixDates();
      });
    }
  }

  initState(x: string) {
    this.currentState = x;
    this.searchReq.status = this.findStateStatus(x);
    this.searchReq.page = 1;
    this.setStateName(x);
    this.search();
  }
  findStateStatus(x: string): string {
    return this.routeStateStatus.filter((n) => n.state === x)[0].status;
  }

  setStateName(x: string): void {
    const state = this.routeStateStatus.filter((n) => n.state === x)[0];
    this.stateName = state.name;
    this.selectedStatus = state.status;
  }

  fixDates() {
    this.posts.content.forEach((x) => {
      x.strDate = moment(x.date).format('D-M-YYYY - H:mm:ss');
    });
  }

  publish(x: IPostSummary) {
    this.ps.publish(x.postId).subscribe(() => {
      this.search(x);
    });
  }

  approval(x: IPostSummary) {
    this.ps.approval(x.postId).subscribe(() => {
      this.search();
    });
  }


  pageEvent(page: number) {
    this.searchReq.page = page;
    this.search();
  }

  trackByMethod(index: number, el: any): number {
    return el.postId;
  }

}
