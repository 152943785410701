import {Component, OnInit} from '@angular/core';
import {AbstractCommonSearch} from '../common/AbstractCommonSearch';
import {IPostSummary} from '../../@types/post';
import {PostService} from '../../_services/post.service';
import {ActivatedRoute} from '@angular/router';
import {GlobalObjectiveService} from '../../_services/global.objective.service';
import {CategoryService} from '../../_services/category.service';

@Component({
  selector: 'app-enthusiasm',
  templateUrl: './enthusiasm.component.html',
  styleUrls: ['./enthusiasm.component.scss']
})
export class EnthusiasmComponent extends AbstractCommonSearch implements OnInit {


  constructor(ps: PostService, route: ActivatedRoute, go: GlobalObjectiveService, cs: CategoryService) {
    super(ps, route, go, cs);
    this.searchReq = {
      type: 'PERSONAL_SOURCE',
      size: 15
    };
    this.routeStateStatus = [
      {
        state: 'waiting',
        status: 'WAITING_APPROVAL',
        name: 'Onay Bekliyor'
      },
      {
        state: 'published',
        status: 'PUBLISHED',
        name: 'Yayinda'
      },
      {
        state: 'archive',
        status: 'FILTERED',
        name: 'Arsiv'
      }];
  }

  archive(x: IPostSummary) {
    this.ps.archive(x.postId).subscribe(() => {
      this.search();
    });
  }

  draft(x: IPostSummary) {
    this.ps.draft(x.postId).subscribe(() => {
      this.search();
    });
  }
}
