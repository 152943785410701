import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ParserService {

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {

  }


  states(): Observable<Array<IParserState>> {
    return <any> this.http.get(this.apiURL + '/admin/parser/states');
  }

}
