import {Component, OnInit} from '@angular/core';
import {ParserService} from '../../_services/parser.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  public states: IParserState[];

  constructor(private ps: ParserService) {
  }

  ngOnInit(): void {
    this.ps.states().subscribe((resp) => {
      this.states = resp;
    });
  }

}
