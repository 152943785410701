<div class="container">

  <!-- Filter Turgay -->
  <div class="row align-items-center mt-4 mb-2">
    <div class="col-lg-5 mb-2 mt-2">
      <h5>Özet</h5>
    </div>

    <div class="col-lg-3 mb-2 mt-2">
    </div>

    <div class="col-lg-4 mb-2 mt-2 d-flex flex-row-reverse bd-highlight">
      <button type="button" class="btn btn-outline-dark btn-sm m-1"><i class="fas fa-filter" aria-hidden="true"></i>
        Filtre
      </button>
    </div>
  </div>

  <!-- Turgay -->
  <div class="row">

    <!-- Sosyal Medya -->
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <img
            src="https://marketingland.com/wp-content/ml-loads/2016/08/social-media-mobile-icons-snapchat-facebook-instagram-ss-800x450-3-800x450.jpg"
            class="card-img-top mb-3" alt="...">
          <h5 class="card-title">
            Parser State
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">Parser Son Rakamlar</h6>

          <div *ngFor="let state of states ">
            <div class="row">
              <div class="col-lg-4 col-md-4"><a href="https://www.instagram.com/pozyorg"
                                                target="_blank">{{state.source}}</a>
              </div>
              <div class="col-lg-4 col-md-4" style="text-align:right;">{{state.date}}</div>
              <div class="col-lg-4 col-md-4" style="text-align:right;">{{state.counter}}</div>

            </div>
            <hr>

          </div>
        </div>
      </div>
    </div>
    <!-- E-Bülten -->
    <!--    <div class="col-lg-3">-->
    <!--      <div class="card">-->
    <!--        <div class="card-body">-->
    <!--          <img src="https://doofindermedia.s3.amazonaws.com/blog/2017/06/05/que%20es%20un%20newsletter.jpg"-->
    <!--               class="card-img-top mb-3" alt="...">-->
    <!--          <h5 class="card-title">-->
    <!--            <div class="row">-->
    <!--              <div class="col-lg-6 col-md-6">E-Bülten</div>-->
    <!--              <div class="col-lg-6 col-md-6" style="text-align:right;">7.815</div>-->
    <!--            </div>-->
    <!--          </h5>-->
    <!--          <h6 class="card-subtitle mb-2 text-muted">sendy üzerindeki pozy bülteni takip eden kullanıcı sayıları</h6>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-6 col-md-6">Pozy_Bulten:</div>-->
    <!--            <div class="col-lg-6 col-md-6" style="text-align:right;">1207</div>-->
    <!--          </div>-->
    <!--          <hr>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-8 col-md-8">Bir_Guzel_Haber:</div>-->
    <!--            <div class="col-lg-4 col-md-4" style="text-align:right;">7.608</div>-->
    <!--          </div>-->
    <!--          <hr>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-8 col-md-8">Gönderilen Bülten</div>-->
    <!--            <div class="col-lg-4 col-md-4" style="text-align:right;">7</div>-->
    <!--          </div>-->
    <!--          <button type="button" class="btn btn-secondary btn-sm btn-block mt-3">https://newsletter.pozy.org</button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; Mobil Uygulamalar &ndash;&gt;-->
    <!--    <div class="col-lg-3">-->
    <!--      <div class="card">-->
    <!--        <div class="card-body">-->
    <!--          <img-->
    <!--            src="https://cdn-gcp.marutitech.com/wp-media/2016/06/7-Trends-that-Define-the-Future-of-Mobile-Application-Development.jpg"-->
    <!--            class="card-img-top mb-3" alt="...">-->
    <!--          <h5 class="card-title">-->
    <!--            Mobil Uygulamalar-->
    <!--          </h5>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-6 col-md-6">App Store</div>-->
    <!--            <div class="col-lg-6 col-md-6" style="text-align:right;">4,9 - 53 Oy</div>-->
    <!--          </div>-->
    <!--          <hr>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-6 col-md-6"><a-->
    <!--              href="https://play.google.com/store/apps/details?id=org.pozy&amp;hl=tr&amp;showAllReviews=true"-->
    <!--              target="_blank">Play Store</a></div>-->
    <!--            <div class="col-lg-6 col-md-6" style="text-align:right;">4,9 - 74 Oy</div>-->
    <!--          </div>-->
    <!--          <button type="button" class="btn btn-secondary btn-sm btn-block mt-3"><i class="fab fa-apple"-->
    <!--                                                                                   aria-hidden="true"></i> iTunes-->
    <!--            Connect-->
    <!--          </button>-->
    <!--          <button type="button" class="btn btn-info btn-sm btn-block mt-3"><i class="fab fa-google-play"-->
    <!--                                                                              aria-hidden="true"></i> Google Play-->
    <!--            Console-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    &lt;!&ndash; Google Analytics &ndash;&gt;-->
    <!--    <div class="col-lg-3">-->
    <!--      <div class="card">-->
    <!--        <div class="card-body">-->
    <!--          <h5 class="card-title">-->
    <!--            Analytics / Hotjar-->
    <!--          </h5>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-8 col-md-8">Lorem</div>-->
    <!--            <div class="col-lg-4 col-md-4" style="text-align:right;">1207</div>-->
    <!--          </div>-->
    <!--          <hr>-->
    <!--          <div class="row">-->
    <!--            <div class="col-lg-8 col-md-8">Lorem</div>-->
    <!--            <div class="col-lg-4 col-md-4" style="text-align:right;">7.608</div>-->
    <!--          </div>-->
    <!--          <button type="button" class="btn btn-secondary btn-sm btn-block mt-3"><i class="fas fa-chart-bar"-->
    <!--                                                                                   aria-hidden="true"></i> Google-->
    <!--            Analytics-->
    <!--          </button>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->


    <!--  </div>-->
    <!-- BOX Turgay -->


  </div>

</div>
