import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DefaultLayoutComponent} from './default-layout-component/default-layout.component';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './default-layout-component/partial/header/header.component';
import {FooterComponent} from './default-layout-component/partial/footer/footer.component';
import {EmptyLayoutComponent} from './empty-layout/empty-layout.component';
import {NgbCollapseModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastModule} from '../toast/toast.module';


@NgModule({
  declarations: [DefaultLayoutComponent, HeaderComponent, FooterComponent, EmptyLayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbCollapseModule,
    NgbDropdownModule,
    ToastModule
  ],
  exports: [DefaultLayoutComponent, EmptyLayoutComponent]
})
export class LayoutModuleModule {
}
