import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IGlobalObjectiveChangeReq} from '../@types/post';

@Injectable({
  providedIn: 'root'
})
export class GlobalObjectiveService {

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {

  }

  loadAll(): Observable<Array<IGlobalObjective>> {
    return <any> this.http.get(this.apiURL + '/admin/objective');
  }

  changeGlobalObjective(req: IGlobalObjectiveChangeReq): Observable<void> {
    return <any> this.http.post(this.apiURL + '/admin/objective/change', req);
  }

}
