<div class="container">

  <!-- Filter Turgay -->
  <div class="row align-items-center mt-4 mb-2">
    <div class="col-lg-5 mb-2 mt-2">
      <h5>{{users.totalContent}} Adet Üye</h5>
    </div>

    <div class="col-lg-3 mb-2 mt-2">
      <div class="input-group md-form form-sm form-2 pl-0">
        <input [(ngModel)]="searchReq.freeText" class="form-control my-0 py-1 red-border" type="text"
               placeholder="içerik adı, haber id..."
               aria-label="Search">
        <div class="input-group-append">
          <span (click)="search()" class="input-group-text red lighten-3" id="basic-text1"><i
            class="fas fa-search text-grey"
            aria-hidden="true"></i></span>
        </div>
      </div>
    </div>


    <div class="col-lg-4 mb-2 mt-2 d-flex flex-row-reverse bd-highlight">

      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label [routerLinkActive]="['active']" routerLink="/app/users/all" class="btn btn-info">
          <input type="radio" name="options" id="option3" autocomplete="off"> Hepsi
        </label>

        <label [routerLinkActive]="['active']" routerLink="/app/users/active" class="btn btn-primary">
          <input type="radio" name="options" id="option2" autocomplete="off"> Aktif
        </label>

        <label [routerLinkActive]="['active']" routerLink="/app/users/passive" class="btn btn-secondary">
          <input type="radio" name="options" id="option1" autocomplete="off" checked> Pasif
        </label>

      </div>
    </div>
  </div>


  <!-- UYE Turgay -->
  <div *ngFor="let user of users.content " class="row newscontainer align-items-center">
    <div class="col-lg-1 mb-2 mt-2">
      <img [src]="user.logo" alt="..."
           class="img-thumbnail rounded-circle">
    </div>
    <div class="col-lg-3 mb-2 mt-2 pl-0">
      <p class="font-weight-bold">{{user.name}}</p>
      <p class="font-weight-light">@{{user.sourceRoute}} <i class="fas fa-check-circle text-primary"
                                                           aria-hidden="true"></i></p>
      <p class="font-weight-bold">{{user.email}}</p>
    </div>

    <div class="col-lg-1 mb-2 mt-2 text-center pr-0 pl-0">
      <p><span class="badge badge-light">#{{user.id}}</span></p>
    </div>

    <div class="col-lg-1 mb-2 mt-2 text-center pr-0 pl-0">
      <p><span class="badge badge-light">{{user.year}}</span></p>
    </div>

    <div class="col-lg-1 mb-2 mt-2 text-center">
      <p><span class="badge badge-success">{{user.status}}</span></p>
    </div>

    <div class="col-lg-2 mb-2 mt-2 text-center">
      <p>{{user.date}}</p>
    </div>

    <div class="col-lg-2 mb-2 mt-2 pl-0 text-center"><p>{{user.userType}}</p></div>

    <div class="col-lg-1 mb-2 mt-2 pl-0">
      <button type="button" class="btn btn-primary btn-sm btn-block">Detay</button>
    </div>

  </div>

  <div *ngIf="users && users.totalContent > searchReq.size" class="col-md-12 d-flex justify-content-center align-items-center mb-3 mt-3">

    <ngb-pagination (pageChange)="pageEvent($event)" [collectionSize]="users.totalContent" [(page)]="searchReq.page"
                    [maxSize]="5"></ngb-pagination>

  </div>

</div>
