import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {PostEditComponent} from './edit/post-edit.component';


const summaryRoutes: Routes = [

  {
    path: ':id', component: PostEditComponent
  }
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(summaryRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PostRoutingModule {
}
