import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutModuleModule} from './modules/layout-module/layout-module.module';
import {ApproveModule} from './pages/approve/approve.module';
import {SummaryModule} from './pages/summary/summary.module';
import {AuthGuard, GuestGuard} from './_guards';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {environment} from '../environments/environment';
import {EnthusiasmModule} from './pages/enthusiasm/enthusiasm.module';
import {AuthModule} from './pages/auth/auth.module';
import {SecurityInterceptor} from './_services/interceptor/security-interceptor';
import {UserModule} from './pages/user/user.module';
import {PostModule} from './pages/post/post.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModuleModule,
    ApproveModule,
    SummaryModule,
    EnthusiasmModule,
    HttpClientModule,
    AuthModule,
    UserModule,
    PostModule
  ],
  providers: [AuthGuard, GuestGuard, {provide: 'environment.apiURL', useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
