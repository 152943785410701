<div class="container">
  <div class="card login-card">

    <div class="card-header bg-white text-center">
      <img src="https://pozy.org/assets/images/pozy-logo.svg" alt="" width="72" height="72">
    </div>

    <div class="card-body ">
      <div class="row  mb-2">
        <div class="col">
          <label for="inputEmail" class="sr-only">Email</label>
          <input type="email" id="inputEmail" class="form-control" placeholder="Email" [(ngModel)]="username"
                 required autofocus>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col">
          <label for="inputPassword" class="sr-only">Sifre</label>
          <input type="password" id="inputPassword" class="form-control" placeholder="Sifre" [(ngModel)]="password"
                 required>
        </div>
      </div>
      <div class="checkbox mb-3">
        <label>
          <input type="checkbox" value="remember-me"> <span class="font-weight-light ml-2 pt-1">Beni hatirla</span>
        </label>
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit" [disabled]="!username || !password"
              (click)="login()">Giris Yap
      </button>
      <p class="mt-5 mb-3 text-muted">&copy; 2017-2020</p>
    </div>
  </div>

</div>
