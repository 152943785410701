import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';


const summaryRoutes: Routes = [

  {
    path: '', component: LoginComponent
  }
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(summaryRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AuthRoutingModule {
}
