import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private meSubject: ReplaySubject<any>;
  private summarySubject = new ReplaySubject<IUserProfileSummary>(1);

  constructor(private http: HttpClient, @Inject('environment.apiURL') private apiURL: string) {
    console.log('created');
  }

  me(invoke?: boolean): Observable<ISimpleUser> {
    if (invoke && this.meSubject) {
      this.http.get(this.apiURL + '/admin/user/me').subscribe((response: any) => {
        this.meSubject.next(response);
      }, error => {
        console.log(error);
        this.meSubject.error(error);
      });
    } else {
      if (!this.meSubject) {
        this.meSubject = new ReplaySubject<ISimpleUser>(1);
        this.http.get(this.apiURL + '/admin/user/me').subscribe((response: any) => {
          this.meSubject.next(response);
        }, error => {
          this.meSubject.error(error);
        });
      }
    }
    return this.meSubject.asObservable();
  }

  search(x: IUserSearchRequest): Observable<IPage<IUser>> {
    return <any> this.http.post(this.apiURL + '/admin/user/search', x);
  }

  login(username: string, password: string): Observable<any> {
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);

    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.http.post(`${this.apiURL}/auth/login`, body.toString(), options);
  }
}
