import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EnthusiasmComponent} from './enthusiasm.component';
import {EnthusiasmRoutingModule} from './enthusiasm-routing.module';
import {FormsModule} from '@angular/forms';
import {NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";


@NgModule({
  declarations: [EnthusiasmComponent],
  imports: [
    CommonModule,
    FormsModule,
    EnthusiasmRoutingModule,
    NgbPaginationModule
  ]
})
export class EnthusiasmModule {
}
