import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PostService} from '../../../_services/post.service';
import {IPostNewsSummary} from '../../../@types/post';
import {ToastService} from '../../../_services/toast.service';

@Component({
  selector: 'app-post-edit',
  templateUrl: './post-edit.component.html',
  styleUrls: ['./post-edit.component.scss']
})
export class PostEditComponent implements OnInit {

  public post: IPostNewsSummary;
  public postId: number;
  public disabled: boolean;

  constructor(private postService: PostService, private route: ActivatedRoute, private ts: ToastService) {
  }

  ngOnInit(): void {
    this.disabled = true;
    this.route.params.subscribe(params => {
      this.postId = params.id;
      this.disabled = false;
      // this.setupComponent(params['someParam']);
      this.postService.loadPostNewsSummary(params.id).subscribe((response) => {
        this.post = response;
      });
    });
  }

  save(): void {
    this.disabled = true;
    this.postService.updatePostNews(this.postId, this.post).subscribe(() => {
      console.log('saved');
      this.ts.showSuccess('İçerik Kaydedildi.');
      this.disabled = false;
    });
  }


}
