import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {ApproveComponent} from './approve.component';

const summaryRoutes: Routes = [
  {
    path: ':state', component: ApproveComponent
  },
  {
    path: '',
    redirectTo: '/app/approve/waiting',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(summaryRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class ApproveRoutingModule {
}
