<div class="container">


  <!-- Kaydet / Sil-->
  <div class="row align-items-center mt-4 mb-2">

    <div class="col-lg-5 mb-2 mt-2">
      <h5>Taslak Edit -&gt; {{postId}}</h5>
    </div>

    <div class="col-lg-3 mb-2 mt-2">
    </div>

    <div class="col-lg-4 mb-2 mt-2 d-flex flex-row-reverse bd-highlight">
      <button [disabled]="disabled" (click)="save()" type="button" class="btn btn-primary m-1"><i class="fas fa-save" aria-hidden="true"></i>
        Kaydet
      </button>
      <!--      <button type="button" class="btn btn-danger m-1"><i class="fas fa-trash" aria-hidden="true"></i> Sil</button>-->
    </div>
  </div>


  <div class="row">

    <!-- SOL -->
    <div class="col-md-4">
      <!-- Kaynak Widget -->
      <div class="card mb-3">
        <h5 class="card-header">Durum</h5>
        <div class="card-body">
          <div class="form-group files">
            <label for="formGroupExampleInput">Cover Fotoğrafı</label>
            <input type="file" class="form-control" multiple="as">
          </div>
          <div class="alert alert-warning" role="alert">
            Fotoğraf boyutlarının 600x600px'den büyük ve maksimum 3MB boyutunda olduğunda lütfen dikkat edin. 😊
          </div>
          <hr>
          <div class="form-group">
            <label for="formGroupExampleInput">Durum</label>
            <select class="form-control">
              <option>Tasarı</option>
              <option>Yayında</option>
              <option>Mükerrer</option>
            </select>
          </div>

          <div class="form-group">
            <label for="formGroupExampleInput">Kategori</label>
            <select class="form-control">
              <option>Gündem</option>
              <option>Kültür Sanat</option>
              <option>Spor</option>
            </select>
          </div>

          <hr>

          <div class="form-group">
            <label for="formGroupExampleInput">Kaynak</label>
            <select class="form-control">
              <option>TRT Haber</option>
              <option>NTV</option>
              <option>Hürriyet</option>
            </select>
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput">Kaynak No</label>
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput">Kaynak Link</label>
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
          </div>

        </div>
      </div>

      <!-- Bilgiler Widget -->
      <div class="card mb-2">
        <h5 class="card-header">Bilgiler</h5>
        <div class="card-body">

          <div class="form-group">
            <label for="formGroupExampleInput">Duygu Analizi</label>
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput">Beğenilme Sayısı</label>
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput">Raporlanma Sayısı</label>
            <input type="text" class="form-control" id="formGroupExampleInput" placeholder="">
          </div>
          <hr>
          <div class="form-group">
            <label for="formGroupExampleInput">Oluşturulma</label>
            <input class="form-control" type="text" placeholder="14-03-2020 17:27" readonly="">
          </div>
          <div class="form-group">
            <label for="formGroupExampleInput">Güncellenme</label>
            <input class="form-control" type="text" placeholder="14-03-2020 17:27" readonly="">
          </div>

        </div>
      </div>

    </div>

    <!-- SAĞ -->
    <div class="col-lg-8">
      <div class="card mb-3">
        <h5 class="card-header">Haber Detay - 341170</h5>
        <div class="card-body">
          <!-- Preview Image -->
          <div class="form-group">
            <label for="formGroupExampleInput">Başlık</label>
            <input [(ngModel)]="post.title" type="text" class="form-control" id="formGroupExampleInput" placeholder="">
          </div>

          <div class="form-group">
            <label for="exampleFormControlTextarea1">Özet</label>
            <textarea [(ngModel)]="post.summary" class="form-control" id="exampleFormControlTextarea1"
                      rows="3"></textarea>
          </div>

          <div class="form-group">
            <label for="exampleFormControlTextarea1">İçerik / HTML Editör</label>
            <textarea [(ngModel)]="post.body" class="form-control" id="exampleFormControlTextarea1"
                      style="height: 1105px" rows="3"></textarea>
          </div>

        </div>
      </div>
    </div>

  </div>

  <!-- Kaydet / Sil-->
  <div class="row align-items-center mb-2">
    <div class="col-lg-5 mb-2">
    </div>

    <div class="col-lg-3 mb-2">
    </div>

    <div class="col-lg-4 mb-2 d-flex flex-row-reverse bd-highlight">
      <button (click)="save()" type="button" class="btn btn-primary m-1"><i class="fas fa-save" aria-hidden="true"></i>
        Kaydet
      </button>
      <!--      <button type="button" class="btn btn-danger m-1"><i class="fas fa-trash" aria-hidden="true"></i> Sil</button>-->
    </div>
  </div>
</div>
