import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard, GuestGuard} from './_guards';
import {DefaultLayoutComponent} from './modules/layout-module/default-layout-component/default-layout.component';


const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/app/home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: './pages/summary/summary.module#SummaryModule',
      },
      {
        path: 'approve',
        loadChildren: './pages/approve/approve.module#ApproveModule',
      },
      {
        path: 'enthusiasm',
        loadChildren: './pages/enthusiasm/enthusiasm.module#EnthusiasmModule',
      },
      {
        path: 'users',
        loadChildren: './pages/user/user.module#UserModule',
      },
      {
        path: 'post',
        loadChildren: './pages/post/post.module#PostModule',
      }
    ]
  },
  {
    canActivate: [GuestGuard],
    path: 'auth',
    // component: EmptyLayoutComponent,
    loadChildren: './pages/auth/auth.module#AuthModule',
  },
  {
    path: '',
    redirectTo: '/app/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/app/home',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
