import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../_services/user.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private userServiceService: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const stateUrl = state.url;
    return new Observable<boolean>((sub) => {
      if (stateUrl.indexOf('/auth') >= 0) {
        sub.next(true);
        sub.complete();
      } else {
        this.userServiceService.me().subscribe((resp) => {
          sub.next(true);
          sub.complete();
        });
      }
    });
  }
}
